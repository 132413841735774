<template>
  <div>
    <v-card outlined height="300px" class="pa-0 my-4">
      <v-card-text v-if="list">
        <v-row>
          <v-col cols="12" sm="6">
            <v-container
              style="max-height: 250px"
              class="overflow-y-auto pa-1 ma-0 border"
            >
              <v-list dense>
                <v-list-item
                  v-for="item in list"
                  :key="item.id"
                  v-model="item.active"
                  no-action
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><b> {{ item.name }} </b
                      ><span v-if="item.type === 'parent'" class="blue--text"
                        >(Parent)</span
                      >
                      <span v-if="!item.isActive" class="red--text">(N/A)</span>
                      has
                      {{ item.items ? item.items.length : 0 }}
                      items</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-checkbox
                    class="pa-0 ma-0"
                    dense
                    hide-details
                    v-model="itemSelected"
                    label="Select"
                    :value="item.id"
                  ></v-checkbox>
                </v-list-item>

                <template v-if="false">
                  <v-list-item
                    v-for="subItem in item.items"
                    :key="subItem.name"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    </v-list-item-content>
                    <small>{{ subItem.available ? '' : 'NA' }}</small>
                    <v-list-item-action>
                      <span>{{ subItem.price | currency }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-container>
          </v-col>

          <v-col v-if="selectedProducts" cols="12" sm="6">
            <v-row>
              <v-btn
                small
                dark
                color="info"
                :disabled="selectedProducts.length == 0"
                :href="convertData"
                :download="`${filename}.json`"
              >
                Export {{ selectedProducts.length }} Menus<v-icon right
                  >get_app</v-icon
                >
              </v-btn>
            </v-row>
            <v-row>
              <span
                v-if="selectedProducts.length > 0"
                class="caption ml-2 mt-2"
              >
                Once you download {{ filename }}.json, please login with
                different account and import.</span
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <import-menus v-if="userProfile" :uid="uid" :userProfile="userProfile" />
  </div>
</template>

<script>
import moment from 'moment'
import ImportMenus from './ImportMenus'
export default {
  props: {
    uid: {
      type: String,
      default: null
    },
    userProfile: {
      type: Object,
      required: true
    }
  },
  components: {
    ImportMenus
  },
  data() {
    return {
      list: null,
      selectedProducts: [],
      data: null,
      itemSelected: []
    }
  },
  computed: {
    filename() {
      return moment().format('MM_DD_YYYY') + '_menus_data'
    },
    convertData() {
      let dData = JSON.stringify(this.selectedProducts, null, 2)

      let blob = new Blob([dData], { type: 'application/json' })

      return window.URL.createObjectURL(blob)
    }
  },
  watch: {
    itemSelected(nv) {
      this.selectedProducts = this.list.filter(item => nv.includes(item.id))
    }
  },
  mounted() {
    this.$store.dispatch('loadMenus').then(list => {
      this.list = list
    })
  },
  methods: {
    inputFileMod(newFile, oldFile) {
      if (this.filesMod.length < 1) {
        return
      }
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }

      const fr = new FileReader()
      fr.readAsText(this.filesMod[0].file)

      fr.onload = evt => {
        this.data = JSON.parse(evt.target.result)
        if (this.data && this.data.length > 0) {
          this.importCount = this.data.length

          this.merge = this.data[0].uid === this.uid
        }
      }
    },
    inputFilterMod(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },

    uploadMod() {
      this.uploadProgress = true

      this.$store
        .dispatch('importMenus', {
          data: this.data,
          uid: this.uid,
          merge: this.merge,
          profile: this.userProfile
        })
        .then(() => {
          this.filesMod = []

          this.$store.dispatch('setMessage', {
            color: 'info',
            title: 'Imported',

            body: 'Menus are imported.'
          })
        })
    }
  }
}
</script>
